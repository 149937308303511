.containerContentPinPage {
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  position: relative;

  .h1PinCode {
    color: #232323;
    font-weight: 700;
    font-size: 30px;
  }

  .paragrapghPinCodePage {
    color: #aaaaaa;
    font-size: 14px;
    margin-bottom: 22px;
    font-weight: 500;
  }
  .bottomAdditionalText {
    color: #aaaaaa;
    font-size: 14px;
    margin-top: 22px;
    font-weight: 500;
  }
  .linkGoLogin {
    display: flex;
    align-items: center;
    color: #21a038;
    font-size: 12px;
    margin-top: 10px;
  }
}

.mainContainer {
  padding: 32px 40px;
  .wrapperHeaderCodes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .containerCards {
    margin-top: 40px;
    width: 100%;
    // height: 100%;
    background: white;
    border-radius: 16px;
    padding: 16px 24px;
    box-shadow: 0px 4px 15px 0px #8990d226;
  }
}

@media screen and (max-width: 500px) {
  .mainContainer {
    padding: 16px 16px 90px 16px;

    .wrapperHeaderCodes {
      flex-direction: column;
      align-items: flex-start;
    }

    .containerCards {
      margin-top: 26px;
    }
  }
}

.wrapperContainerCards {
  padding: 32px 40px;
  .wrapperContainerAllCards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .containerNavCards {
    margin-bottom: 40px;
  }
  .containerCards {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 16px;
    padding: 16px 24px;
  }
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .wrapperContainerCards {
    padding: 16px 16px 90px 16px;

    .wrapperContainerAllCards {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

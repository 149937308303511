.containerModal {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.198);
  z-index: 10000;
  overflow: auto;

  .modal {
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 32px);
    height: fit-content;
    background: white;
    z-index: 111000;
    border-radius: 16px;

    .closePinModalIcon {
      position: absolute;
      top: 14px;
      right: 14px;
      height: 20px;
      cursor: pointer;
      width: 20px;
    }
    .content {
      max-width: 700px;
      padding: 32px 40px;
    }
  }
}

@media screen and (max-width: 500px) {
  .content {
    padding: 22px 30px !important;
    min-width: 340px;
  }
}

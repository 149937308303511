.containerDataRangePicker {
  position: relative;
  display: inline-block;

  .buttonOpenRangePicker {
    padding: 4px 8px;
    border-radius: 8px;
    border: 1px solid #ccc;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    color: #232323;
    width: 220px;
    font-weight: 600;
  }

  .wrapperRangePicker {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 9999;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 14px;

    .containerRangePicker {
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      .presetsButtons {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .presetButton {
          background: none;
          border-radius: 8px;
          padding: 2px 6px;
          cursor: pointer;
          color: #21a038;
          white-space: nowrap;
          font-size: 14px;
        }
      }
    }

    .containerDayPicker {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-end;

      .buttonAcceptRange {
        background: #21a038;
        border: 1px solid #21a038;
        border-radius: 8px;
        padding: 4px 18px;
        cursor: pointer;
        color: white;
        white-space: nowrap;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .containerDataRangePicker {
    .wrapperRangePicker {
      max-width: 360px;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      overflow: hidden;

      .containerRangePicker {
        overflow: hidden;
        width: 100%;
        .presetsButtons {
          overflow: auto;
          height: 40px;
          flex-direction: row !important;
          padding: 2px;
          .presetButton {
            font-size: 13px;
            padding: 4px 6px;
            height: fit-content;
          }
        }
      }
    }
  }
}

@import "../../styles/vars";

.containerRateAndNews {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .containerNews {
    padding: 16px 24px;
    background: white;
    border-radius: 16px;
    width: 513px;
    box-shadow: 0px 4px 15px 0px rgba(137, 144, 210, 0.1490196078);

    .containerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;

      .linkTitle {
        color: $accent-color;
      }
    }
  }

  .containerNewsTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 24px;

    .newsSkeleton {
      width: 100%;
      height: 326px;
    }
    .containerNewOne {
      display: flex;
      align-items: center;
      gap: 6px;
      width: 100%;
    }
    .dateColor {
      color: $secondary-font;
    }
    .linkCircle {
      width: 32px;
      height: 32px;
      background: $accent-color;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .titleNewsColor {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 200px;
      cursor: pointer;
      position: relative;
      color: $primary_font;
    }
  }
}

.noDataAvailableTitle {
  text-transform: uppercase;
  color: #aaaaaa;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.containerRateAndNewsSmall {
  .containerNews {
    padding: 16px 24px;
    background: white;
    border-radius: 16px;
    width: 100%;
    box-shadow: 0px 4px 15px 0px rgba(137, 144, 210, 0.1490196078);
    display: block;
    // max-height: 124px;
    overflow: hidden;
    height: 100%;
    .containerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;

      .linkTitle {
        color: $accent-color;
      }
    }
  }

  .containerNewsTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 14px;
    max-height: 250px;
    overflow: auto;

    .newsSkeleton {
      width: 100%;
      height: 326px;
    }
    .containerNewOne {
      display: flex;
      align-items: flex-start;
      gap: 6px;

      width: 100%;
    }
    .dateColor {
      color: $secondary-font;
    }
    .linkCircle {
      width: 22px;
      height: 22px;
      background: $accent-color;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .titleNewsColor {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // max-width: 200px;
      cursor: pointer;
      position: relative;
      color: $primary_font;
    }
  }
}
.containerTitleText {
  display: flex;
  align-items: center;
  gap: 4px;
}
// @media screen and (min-width: 1919px) and (max-width: 4000px) {
//   .containerRateAndNewsSmall {
//     display: none;
//   }
//   .containerNewsMobile {
//     display: none;
//   }
// }

// @media screen and (min-width: 500px) and (max-width: 1919px) {
//   .containerRateAndNews {
//     display: none;
//   }
//   .containerNewsMobile {
//     display: none;
//   }
//   .containerRateAndNewsSmall {
//     display: flex;
//     flex-direction: row;
//     gap: 20px;
//     .containerNews {
//       max-height: 124px;
//       overflow: auto;
//     }
//   }
// }

// @media (max-width: 500px) {
//   .containerNews,
//   .containerRateAndNews {
//     display: none !important;
//   }
//   .containerNewsMobile {
//     padding: 22px 24px;
//     background: white;
//     border-radius: 16px;
//     width: 513px;
//     box-shadow: 0px 4px 15px 0px rgba(137, 144, 210, 0.1490196078);
//     max-width: 100%;
//     max-height: 248px;
//     overflow: auto;
//     .containerTitle {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       margin-bottom: 22px;

//       .linkTitle {
//         color: $accent-color;
//         font-size: 13px;
//       }
//     }
//   }
// }

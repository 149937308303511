.wrapperPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../../public/images/Background.svg) no-repeat center center
    fixed;
  background-size: cover;

  .containerContentPinPage {
    display: flex;
    justify-items: center;
    flex-direction: column;
    align-items: center;
    width: 500px;

    .h1PinCode {
      color: #232323;
      font-weight: 700;
      font-size: 30px;
    }

    .paragrapghPinCodePage {
      color: #aaaaaa;
      font-size: 14px;
      margin-bottom: 22px;
    }

    .titleBackLogin {
      font-size: 12px;
      margin-top: 20px;
      display: flex;
      align-items: center;

      color: #aaaaaa;
      a {
        margin-left: 4px;
        color: #21a038;
      }
    }
  }
}

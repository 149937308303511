.titleBackLogin {
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  align-items: center;

  color: #aaaaaa;
  a {
    margin-left: 4px;
    color: #21a038;
  }
}

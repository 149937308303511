.paymentsContainer {
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .wrapperHeaderPayments {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .paymentsContainerHeader {
    padding: 20px 36px;
    background-color: white;
    width: fit-content;
    box-shadow: 0px 4px 15px 0px #8990d226;
    border-radius: 16px;
  }

  .paymentsTableContainer {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 4px 15px 0px #8990d226;
    padding: 16px 24px;

    .paymentsTableHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      .paymentsTableHeaderLeft {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .paymentsTable {
    max-width: 100%;
    overflow: auto;
    margin-bottom: 15px;
    tbody,
    tr,
    td {
      padding: 1rem 1rem !important;
    }

    thead {
      tr {
        th {
          white-space: nowrap;
        }
      }
    }
  }
}

.paymentsHead {
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #aaaaaa;

  tr,
  th {
    color: #aaaaaa !important;
    font-weight: 600 !important;
  }
}

.paymentsBody {
  .columnPaymentDetails {
    white-space: nowrap;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: auto;
  }
  .columnPaymentDetails:hover {
    text-overflow: unset;
    overflow: auto;
  }
  tr {
    background-color: white;
    td {
      height: 56px;
      font-size: 14px;
      background-color: white;

      span {
        border-radius: 4px;
        background-color: #ececec;
        padding: 2px 5px;
        color: #aaaaaa;
        font-size: 12px;
      }

      .refillMethod {
        display: flex;
        color: green;
        width: 120px;
        justify-content: center;
      }

      .paymentsBodyAccountSpan {
        background-color: #ececec;
        color: #aaaaaa;
      }

      .paymentsBodyCardNameSpan {
        background-color: #ececec;
        color: #21a038;
        padding: 0 17.5px;
        border-radius: 4px;
        max-width: calc(20ch + 35px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .paymentsBodyCardNumber {
      display: flex;
      align-items: center;
      gap: 4px;
      white-space: nowrap;
      max-height: 75px;
      .paymentsBodyCardNumberIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        width: 46.5px;
        svg {
          max-height: 24px;
          width: fit-content;
        }

        img {
          max-height: 24px;
        }
      }

      .paymentsBodyCardNumberSpan {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: #21a038;
        color: white;
        border-radius: 4px;
        white-space: nowrap;
      }

      .paymentsBodyQuestionSpan {
        background-color: transparent;
        display: flex;
        align-items: center;
      }
    }
  }
}

.skeletonTable {
  width: 100%;
  height: 20vh;
}

.applyFiltersButton {
  background-color: #232323 !important;
  padding: 9px 32px;
  color: white;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 600;
}

.clearFiltersButton {
  background-color: transparent;
  border: 1px solid #232323;
  color: #232323 !important;
  font-weight: 600;
  font-size: 16px;
  padding: 9px 32px;
  white-space: nowrap;

  color: white;
}

.inputWidths {
  min-width: 23%;
}

.filters {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #f3f3f3;
  .clearFiltersButton {
    &:last-child {
      margin-left: 16px;
    }
  }
}
.paymentsHeaderMobile {
  display: none;
}
.datePickerInput {
  min-width: 23%;
  width: 23%;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .paymentsContainer {
    padding: 16px 16px 90px 16px;

    .wrapperHeaderPayments {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .paymentsTableHeader {
    flex-direction: column;
    gap: 12px;
  }

  .paymentsHeaderMobile {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;

    justify-content: space-between;

    .balanceHistoryHeaderTitle {
      h3 {
        width: 120px;
      }
      display: flex;
    }
  }

  .paymentsHeaderButtonsMobile {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
  }

  .datePickerInput {
    min-width: 100% !important;
  }

  .inputWidths {
    min-width: 100% !important;
  }
  .filterButtons {
    display: flex;
    justify-content: space-between;
    button {
      width: 45%;

      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
    }
  }
}

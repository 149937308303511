.containerContentFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  .containerPinFields {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .fieldContainer {
      width: 82px;
      height: 82px;
      border-radius: 16px;
      background: #ececec;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      border: none;
      outline: none;

      &:focus-visible {
        border: 1px solid #c3c1c1;
      }
    }
  }
  .buttonConfirmPins {
    padding: 9px 56px;
  }
  .textError {
    color: #ff4d4d;
    font-size: 14px;
    margin-top: -10px;
  }
}

.accountsPage {
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .wrapperHeaderAccounts {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .accountPageHeader {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .accountsBlockInfoContainer {
    display: flex;
    gap: 20px;
    .widgetSkeleton {
      width: 50px;
      height: 44px;
    }
  }

  .tableContainer {
    box-shadow: 0px 4px 15px 0px #8990d226;
    border-radius: 16px;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .accountsPage {
    padding: 16px 16px 90px 16px;
    .wrapperHeaderAccounts {
      flex-direction: column;
      align-items: flex-start;
    }
    .accountsBlockInfoContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      a {
        display: none;
      }

      h3 {
      }
    }
  }
}

.mainWrapperSpendingChart {
  width: 100%;
  height: 268px;
  width: 100%;
  background: white;
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px rgba(137, 144, 210, 0.1490196078);

  .containerSpendingChart {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .containerTitleSpendingChart {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .wrapperSimpleChart {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }
  }
}

.mainWrapper {
  padding: 32px 40px;
  .wrapperHeaderTM {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  // p {
  // }
  .containerTable {
    padding: 16px 24px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 4px 15px 0px #8990d226;
  }
}

@media screen and (max-width: 500px) {
  .mainWrapper {
    padding: 16px 16px 90px 16px;

    .wrapperHeaderTM {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@import "../../styles/vars";

.containerItemBlock {
  width: 100%;
  // min-height: 124px;
  height: 124px;
  background: white;
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #8990d226;
  .containerContentItemBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .containerTitle {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .contentContainer {
    display: flex;
    justify-content: space-between;

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .containerAdditional {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .linkTitle {
      color: $accent-color;
      white-space: nowrap;
    }
  }
  .highlight {
    color: $accent-color;
  }
  .titleNormal {
    color: $primary_font;
  }
  .titleColored {
    color: $accent-color;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .containerItemBlock {
    .containerContentItemBlock {
      width: 100%;
    }
    .titles {
      line-height: 10px;
    }
  }
  .titleNormal,
  .titleColored {
    font-size: 13px !important;
  }
  .containerAdditional {
    a {
      font-size: 13px;
    }
  }
}

.disableLink {
  display: none;
}

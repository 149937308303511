.containerDashboard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
  width: 100%;

  .containerTwoDashboardInfoElements {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    align-items: flex-start;
    width: 100%;

    .containerWithDashboardInfo {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px;
    }
    .containerSpendingChart {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .containerWithCardsInfo,
    .containerNewsAndDeclineRate {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
  .widgetSkeleton {
    width: 50px;
    height: 44px;
  }
  .widgetSkeletonWithCard {
    width: 150px;
    height: 44px;
  }
  .containerImgCard {
    display: flex;
    align-items: center;
    gap: 10px;
    .cardText {
      color: #aaaaaa;
    }
    .twoRowsText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .conainerAllCards {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    p {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.containerDashboardTableAndNews {
  display: grid;
  grid-template-columns: minmax(0, 7fr) minmax(0, 3fr);
  gap: 20px;
  width: 100%;

  .containerTableDashboard {
    width: 100%;
    border: 1px solid transparent;
    border-radius: 16px;
    background: white;
    padding: 16px 24px;
    box-shadow: 0px 4px 15px 0px #8990d226;
  }
}

//new
@media (max-width: 1550px) {
  .containerDashboard {
    .containerTwoDashboardInfoElements {
      grid-template-columns: 1fr;
    }
  }

  .containerDashboardTableAndNews {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    .containerTableDashboard,
    .containerNewsAndDeclineRate {
      min-width: 0;
    }
  }
}
@media (max-width: 990px) {
  .containerDashboard {
    .containerTwoDashboardInfoElements {
      .containerWithDashboardInfo {
        grid-template-rows: auto;
      }
      .containerWithCardsInfo,
      .containerNewsAndDeclineRate {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media (max-width: 500px) {
  .containerDashboard {
    .containerTwoDashboardInfoElements {
      .containerWidgets {
        display: flex;
        flex-direction: column;
      }
      .containerWithDashboardInfo {
        grid-template-rows: auto;
      }
      .containerWithCardsInfo,
      .containerNewsAndDeclineRate {
        grid-template-columns: 1fr;
      }
    }
  }
}

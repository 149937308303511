@import "../../styles/vars";

.serviceItemsPage {
  padding: 32px 40px;
  .wrapperHeaderServices {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .serviceItems {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
    gap: 40px;
    margin-top: 24px;
  }
  .linkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: $accent-color;
    .linkP {
      color: $accent-color;
      font-size: 14px;
    }
  }
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .serviceItemsPage {
    padding: 16px 16px 90px 16px;

    .wrapperHeaderServices {
      flex-direction: column;
      align-items: flex-start;
    }
    .serviceItems {
      gap: 16px;
    }
  }
}

.dashboardMain {
  padding: 32px 40px 36px 40px;
  .wrapperHeaderDashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .dashboardTitleContainer {
      display: flex;
      width: fit-content;
      align-items: center;
      gap: 7px;

      .reloadIcon {
        cursor: pointer;
      }
      .title {
        color: black;
      }
    }
    .centerMainContent {
      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 100%;
    }
  }
}
.wrapperDataPickerDashboard {
  margin-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .dashboardMain {
    padding: 16px 16px 90px 16px;
    .wrapperHeaderDashboard {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.containerRate {
  padding: 16px 24px 20px 24px;
  height: 124px;
  border-radius: 16px;
  background: white;
  box-shadow: 0px 4px 15px 0px rgba(137, 144, 210, 0.1490196078);

  .rateNumber {
    font-size: 24px;
  }

  .titleINfoDeclineRate {
    font-size: 12px;
    color: #464646;
  }
  .containerTitle {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    gap: 4px;
    margin-bottom: 16px;
    span {
      height: 22px;
    }
    p {
      white-space: nowrap;
    }
  }
  .widgetSkeleton {
    width: 50%;
    height: 44px;
  }
  .wrapperContainerProcents {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .containerProcents {
      width: 222px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 2px 34px;
      border-radius: 16px;

      span {
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .containerRate {
    width: 100%;
  }
  .containerRate {
    .rateNumber {
      font-size: 20px;
    }
    .wrapperContainerProcents {
      .containerProcents {
        flex-direction: column;
        gap: 0px;
        padding: 2px 22px;
      }
    }
  }
}

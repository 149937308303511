@import "../../styles/vars";

.loginContainerRegistration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 16px 32px;
  margin-top: 20px;

  background-color: white;
  box-shadow: 0px 4px 15px 0px #8990d226;
  border-radius: 16px;

  .loginContainerRegistrationText {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 60%;
    .loginRecoverLink {
      color: $accent-color;
    }
  }
  .loginContainerRegistrationButton {
    a {
      display: flex;
      align-items: center;
      color: white;
      gap: 8px;
      padding: 9px 16px;
    }
  }
  .primaryText {
    white-space: nowrap;
  }
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .loginContainerRegistration {
    flex-direction: column;
    max-width: 328px;
    padding: 16px 32px !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 14px;
    margin-top: 32px !important;

    .loginContainerRegistrationText {
      width: 100% !important;

      align-items: center;
    }
    .loginContainerRegistrationButton {
      width: 100%;

      a {
        width: 100%;
        justify-content: center !important;
      }
    }
  }
}

.profileSettigns {
  background-color: white;
  padding: 26px 24px;
  border-radius: 16px;
  color: #232323;
  box-shadow: 0px 4px 15px 0px #8990d226;

  .flexRow {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 2px solid #f3f3f3;
    width: 100%;
    min-height: 64px;
    .profileChangeLogout {
      background: none;
      color: #ff4d4d;
      border: 1px solid#ff4d4d;
      width: 110px;
    }
    span {
      font-size: 14px;
      color: #464646;
      font-weight: 500;
    }

    .statusIsActive {
      background-color: #d9ffe0;
      color: #21a038;
      padding: 3px 17.5px;
      width: fit-content;
      border-radius: 4px;
    }

    .twoFaSpan {
      font-size: 12px;
    }
  }
  .profileTimeZoneContainer,
  .profileChangeContainer {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
    }
    .textSkeleton {
      width: 100px;
      height: 24px;
      background: linear-gradient(90deg, #f2f2f2 25%, #ffffff 50%, #f2f2f2 75%);
      background-size: 200% 100%;
      animation: loading 1.5s infinite;
      border-radius: 16px;
    }
  }
  .selectTwoFaChange {
    width: 300px;
    cursor: pointer;
  }
  .selectTwoFaChange:hover {
    border-color: #21a038;
  }
  .selectTwoFaChange:focus-within {
    border-color: #21a038;
  }

  .selectTwoFaChange.selected {
    border-color: red;
  }
}

.changeProfileButtons {
  color: #21a038;
  margin-left: 16px;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #21a038;
  padding: 5px 17.5px;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
.labelsFont {
  font-size: 16px !important;
  font-weight: 400;
}

@keyframes loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@media screen and (max-width: 1200px) {
  .profileSettigns {
    .flexRow {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 500px) {
  .css-b62m3t-container {
    width: 100% !important;
  }
  .profileSettigns {
    .flexRow {
      display: flex;
      align-items: start;
      flex-direction: column;
      text-align: start;
      gap: 8px;
    }
  }

  .profileSettigns {
    .profileTimeZoneContainer {
      flex-direction: column;
      gap: 8px;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
    button {
      margin: 0px;
    }
  }

  .profileChangeContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .selectTwoFaChange {
    width: 100%;
  }
  // span {
  //   font-size: 14px;
  // }
  h2 {
    font-size: 15px;
  }

  .flexRowMobile {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    h2 {
      width: 170px;
    }
    button {
      align-items: center;
      width: fit-content;
    }
  }
}
